/* Adapted from Volto src/components/manage/Blocks/Listing/SummaryTemplate.jsx

   See https://training.plone.org/effective-volto/addons/customlistingtemplate.html
   */

import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import './descriptionlisttemplate.css';

import { isInternalURL } from '@plone/volto/helpers/Url/Url';

const DescriptionListTemplate = ({
  items,
  linkTitle,
  linkHref,
  isEditMode,
}) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }

  return (
    <>
      <div className="items">
        <dl>
          {items.map((item) => (
            <div className="listing-item" key={item['@id']}>
              <ConditionalLink item={item} condition={!isEditMode}>
                <div className="listing-body">
                  <dt>{item.title ? item.title : item.id}</dt>
                  <dd>{item.description}</dd>
                </div>
              </ConditionalLink>
            </div>
          ))}
        </dl>
      </div>

      {link && <div className="footer">{link}</div>}
    </>
  );
};

DescriptionListTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export { DescriptionListTemplate };
