import { DescriptionListTemplate } from './components/DescriptionListTemplate';
import './theme/general.css';
import './theme/dropdownmenu.css';

const applyConfig = (config) => {
  config.settings = {
    ...config.settings,
    isMultilingual: false,
    supportedLanguages: ['en'],
    defaultLanguage: 'en',
  };

  /* Enable title-block deletion. */
  /*config.blocks.requiredBlocks = [];*/

  // dropdownmenu `selfInContents` is default true.
  // if (config.settings.dropdownmenu) {
  //  config.settings.dropdownmenu.selfInContents = true;
  // }

  // https://6.dev-docs.plone.org/volto/configuration/workingcopy.html
  config.settings.hasWorkingCopySupport = true;

  // Re-enable GDPR non-compliant blocks, which are inhibited by @eeacms/volto-eea-kitkat:
  [
    config.blocks.blocksConfig.video,
    config.blocks.blocksConfig.maps,
    config.blocks.blocksConfig.html,
  ].forEach((arg) => {
    if (arg) {
      arg.restricted = false;
    }
  });

  if (config.experimental.addBlockButton) {
    // https://github.com/plone/volto/blob/621c5a727a0f3d9a03cbf58b64bd94c5d8834147/CHANGELOG.md#1600-alpha53-2022-11-18
    // Experimental setting to move the button for adding a new block to show
    // below any selected block, instead of only on the left of empty text
    // blocks. This rearrangement of the add button or something very like it
    // will likely eventually be the default.
    config.experimental.addBlockButton.enabled = true;
  }

  if (config.blocks.blocksConfig.listing) {
    //   https://github.com/plone/volto/blob/master/docs/source/blocks/settings.md
    var variations = config.blocks.blocksConfig.listing.variations;
    if (variations) {
      // Negate isDefault in already-present variations entries:
      variations.forEach((arg) => {
        if (arg.isDefault) {
          arg.isDefault = false;
        }
      });
      // Add DescriptionListTemplate as default:
      variations.push({
        id: 'descriptionlist',
        title: 'Description list',
        isDefault: true,
        template: DescriptionListTemplate,
      });
    }
  }

  if (config.blocks.blocksConfig.heading) {
    // Include all (provided) heading levels in @kitconcept/volto-heading-block:
    config.blocks.blocksConfig.heading.allowed_headings = [
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
    ];
  }

  // (Places to check for package ids:
  //   look for `config.blocks.blocksConfig.[ID]` in package's `src/index.js` or `id: [ID]`.)

  // add mostUsed:
  [
    config.blocks.blocksConfig.text,
    config.blocks.blocksConfig.description,
    config.blocks.blocksConfig.toc,
    config.blocks.blocksConfig.listing,
    config.blocks.blocksConfig.heading,
    config.blocks.blocksConfig.slate,
    config.blocks.blocksConfig.image,
    config.blocks.blocksConfig.html,
    config.blocks.blocksConfig.columnsBlock,
    config.blocks.blocksConfig.accordion,
    config.blocks.blocksConfig.columnsBlock,
    config.blocks.blocksConfig.codeBlock,
    config.blocks.blocksConfig.dividerBlock,
    config.blocks.blocksConfig.carousel,
  ].forEach((arg) => {
    if (arg) {
      arg.mostUsed = true;
    }
  });

  // remove mostUsed:
  [
    config.blocks.blocksConfig.video,
    config.blocks.blocksConfig.__grid,
    config.blocks.blocksConfig.teaserGrid,
    config.blocks.blocksConfig.imagesGrid,
    config.blocks.blocksConfig.teaser,
    config.blocks.blocksConfig.slider,
  ].forEach((arg) => {
    if (arg) {
      arg.mostUsed = false;
    }
  });

  return config;
};

export default applyConfig;
